import React from 'react';
import './ServicesLayer.css'
import { Link } from 'react-router-dom';
const ServicesLayer = () => {
    return <>
<div className="Services-bg   flex items-center justify-center">
  <div className="absolute inset-0 bg-black opacity-50"></div>
  <div className="relative z-10 text-white flex flex-col gap-4 items-center justify-center h-full text-center px-4 ">
  <h1 className="text-[28px] sm:text-[36px] md:text-[40px] lg:text-[44px] font-semibold tracking-wide main-color pt-5">
  Creative Safety Consultants (CSC)
    </h1>
    <p className="text-[16px] sm:text-[18px] md:text-[22px] lg:text-[24px] w-full sm:w-[90%] md:w-[75%] lg:w-[60%] tracking-wider">
    Are experienced and dedicated health, safety environmental (HSE) professionals who use their knowledge, skills and experience     </p>
    <div className="flex  sm:flex-row gap-4 sm:gap-8 pt-5 text-[12px] sm:text-[20px] lg:text-[22px]">
    <Link to={{ pathname: '/services', state: { current: 'Services' } }}>
        <button className="flex items-center px-3 py-3 bg-[#F0940D] hover:bg-[#e0830d] transition duration-500 ease-in-out rounded-lg">
          Our Services
          <svg
            className="w-5 sm:w-6 h-5 sm:h-6 ml-2"
            viewBox="0 0 31 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.16671 12L29.8334 12M29.8334 12L19.0834 22.75M29.8334 12L19.0834 1.25"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </Link>

      <Link to={{ pathname: '/contact', state: { current: 'Contact Us' } }}>
        <button className="flex items-center px-3 py-3 border border-white transition duration-500 ease-in-out bg-transparent rounded-lg">
          Contact Us
          <svg
            className="w-5 sm:w-6 h-5 sm:h-6 ml-2"
            viewBox="0 0 31 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.16671 12L29.8334 12M29.8334 12L19.0834 22.75M29.8334 12L19.0834 1.25"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </Link>

    </div>
  </div>
</div>

        
    </>
}

export default ServicesLayer;
