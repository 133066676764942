import React from 'react';
import AboutUsLayer from '../../Layers/About_usLayer';
import axios from 'axios';
import { useQuery } from 'react-query';
import ScrollToTop from '../../../ScrollToTop';
import Loading from '../../loading/Loading';

const OurClients = () => {
  
  function getclient() {
    return axios.get(`https://safty.camp-coding.site/api/categories`);
  }
  
  let { data, isLoading, isError } = useQuery("alldata", getclient);

  return (
    <>
      <ScrollToTop />
      <AboutUsLayer />
      <div className="p-8 textNav">
        {/* Section Header */}
        <h1 className="text-center text-3xl font-bold mb-6 main-color">Our Clients</h1>

        {isLoading ? (
          <div className="flex justify-center items-center">
            <Loading />
          </div>
        ) : isError ? (
          <div className="flex justify-center items-center">
            <p className="text-red-500 text-xl">Error loading Clients.</p>
          </div>
        ) : (
          data?.data?.data && Array.isArray(data.data.data) ? (
            data.data.data.map((item) => (
              <div className="mb-12" key={item?.id}>
                <h2 className="xl:text-3xl w-fit p-2 rounded-md text-white bg-[#F0940D] lg:text-2xl md:text-xl text-lg font-bold mb-4">
                  {item?.categoryName}
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 gap-4">
                  {item?.Clients && Array.isArray(item.Clients) ? (
                    item.Clients.map((client) => (
                      <div className="p-3 flex justify-center items-center border-2 rounded-lg" key={client?.id}>
                        <img
                          loading='lazy'
                          src={`https://safty.camp-coding.site/storage/app/public/clients/${client?.clientImageUrl}`}
                          alt={client?.clientName}
                          className="w-full lg:h-[200px] object-contain h-[150px]"
                        />
                      </div>
                    ))
                  ) : (
                    <p>No clients available.</p>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p>No categories available.</p>
          )
        )}
      </div>
    </>
  );
};

export default OurClients;
