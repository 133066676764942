import React, { useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import axios from 'axios';
import { useQuery } from 'react-query'
const CardClients = () => {

  function getapi(){
    return axios.get(`https://safty.camp-coding.site/api/company-clients`)
  }
  let {data ,refetch} = useQuery("dataclient", getapi)
  console.log(data)
  useEffect(() => {
    refetch() 
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000, // Time between slides (in ms)
  speed: 1200, // Transition speed
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:5,
          slidesToScroll: 3
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        },
      },
    ],
  };

  return (
    <div className="p-5">
     <Slider {...settings}>
  {data?.data?.data?.map((image, index) => (
    <div key={index} className="p-2">
      <div className="rounded-lg p-3 border-2">
        <img
          src={image?.clientImageUrl}
          alt={`Slide ${index}`}
          className="w-full h-[200px] object-contain "
        />
      </div>
    </div>
  ))}
</Slider>

      
    </div>
  );
};

export default CardClients;
