import React from 'react';
import Services from '../Services/ServicesSections';
import Clients from '../Clients/Clients';
import Carousel from './Carousel/Carousel';
import ScrollToTop from '../../ScrollToTop';



const Home = () => {
    
    return<>
      <ScrollToTop />

    <Carousel/>
   

    <Services/>
    <Clients/>
    </>
}

export default Home;
