import React from 'react'
import AboutUsLayer from '../../Layers/About_usLayer'
import Clients from '../../Clients/Clients'
import img from "../../../Assets/Frame 99.png"

import imgrgtange from "../../../Assets/Polygon 2.png"
import ScrollToTop from '../../../ScrollToTop'

export default function Advantages() {
  return (
    <div>
            <ScrollToTop />

        <AboutUsLayer/>
        <div className='flex justify-center items-center my-[60px]'>
            <p className='text-[#F0940D] font-bold text-[35px]'>Our Advantages
            </p>
        </div>
       <div className='relative mb-[30px]'>
       <img src={imgrgtange} alt='imag' className='absolute top-[-100px] w-[200px]'/>
       <div className='lg:grid grid-cols-12'>
         
 
         <div className='col-span-6 ps-[50px]'>
        <img src={img} alt='goals' className='h-[500px]'/>
                    </div>
                    <div className='col-span-6 lg:pe-[50px] lg:ms-[-100px]  mt-[35px]'>
        <div className='w-full shadow-xl rounded-lg px-[20px] bg-[white]  py-[30px]'>
        <p className='text-[23px]'> 
        As part of our tailored service, each of our clients benefit from access to a named QHSSE Consultant, who will get to know 
your organization, the particular challenges you face and your specific objectives so that they can implement a safety 
management system that works for you
          </p>
       
        </div>
        </div>
        
        
                </div>
       </div>

        <Clients/>
    </div>
  )
}
