import React from 'react'
import AboutUsLayer from '../../Layers/About_usLayer'
import Clients from '../../Clients/Clients'
import img from "../../../Assets/Frame 99.png"

import imgrgtange from "../../../Assets/Polygon 2.png"
import ScrollToTop from '../../../ScrollToTop'

export default function Mission() {
  return (
    <div>
            <ScrollToTop />

        <AboutUsLayer/>
        <div className='flex justify-center items-center my-[60px]'>
            <p className='text-[#F0940D] font-bold text-[35px]'>OUR Mission</p>
        </div>
       <div className='relative mb-[30px]'>
       <img src={imgrgtange} alt='imag' className='absolute top-[-100px] w-[200px]'/>
       <div className='lg:grid grid-cols-12'>
         
 
         <div className='col-span-6 ps-[50px]'>
        <img src={img} alt='goals' className='h-[500px]'/>
                    </div>
                    <div className='col-span-6 lg:pe-[50px] lg:ms-[-100px]  mt-[35px]'>
        <div className='w-full shadow-xl rounded-lg px-[20px] bg-[white]  py-[30px]'>
        <p className='text-[23px]'> 
  Creative Safety Consultants (CSC) are experienced and dedicated Quality, Health & Safety, Security & Environmental 
(QHSSE) professionals who use their knowledge, skills and experience to:
          </p>
          <ul className=''>
            <li className='p-2 text-[20px]'>Promote a positive quality, health, safety & environmental culture across an organization</li>
            <li className='p-2 text-[20px]'>Develop solutions to quality health, safety & environmental challenges</li>
            <li className='p-2 text-[20px]'>Ensure workplace risks are well controlled; and</li>
            <li className='p-2 text-[20px]'>Ensure the organization is compliant with all relevant legislation & standards</li>
          </ul>
        </div>
        </div>
        
        
                </div>
       </div>

        <Clients/>
    </div>
  )
}
