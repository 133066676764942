import React, { useState } from 'react'
import careerimg from "../../Assets/Rectangle 161.png"
import { z } from "zod";
import emailjs from "@emailjs/browser";
import toast, { Toaster } from "react-hot-toast";
import ScrollToTop from '../../ScrollToTop';
import axios from 'axios';

export const formSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  email: z.string().email("Please insert a valid email address"),
  location: z.string().min(1, "Location is required"),
  phone: z.string().min(11, "Please insert a valid phone number"),
  summary: z.string().min(1, "Please insert a summary"),
  pdfLink: z.string().url("Please provide a valid URL"), // Validate the URL
});
export default function Career() {
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [isLoadingPDf, setLoadingPdf] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    location: '',
    phone: '',
    summary: '',
    pdfLink: '' // Change the key to pdfLink
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const uploadPDF = async (file) => {
    const uploadForm = new FormData();
    uploadForm.append('pdf', file);
    setLoadingPdf(true)
    try {
      const response = await axios.post('https://safty.camp-coding.site/api/upload-pdf', uploadForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoadingPdf(false)


      if (response.data && response.data.pdf_url) {
        setFormData((prevData) => ({
          ...prevData,
          pdfLink: response.data.pdf_url, // Set the uploaded PDF URL in formData
        }));
        toast.success("PDF uploaded successfully!");
      }
    } catch (error) {
      console.error("Error uploading PDF:", error);
      toast.error("Failed to upload PDF.");
    }
  };

  // Handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadPDF(file);
    }
  };
  // Handle file input change

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = formSchema.safeParse(formData);
    if (!result.success) {
      const errors = result.error.format();
      setFormErrors(errors);
      return;
    }
    setLoading(true);
    setFormErrors({});
   const service_id= 'service_d0tfqta'
   const template_id= 'template_o9aqdhu'
   const publicKey= 'IV1q0xSpvY_rGdWv6'
   const template_params= {
    from_name: `${formData.firstName} ${formData.lastName}`,
    from_email: formData.email,
    to_name: "Safety Company",
    summary: formData.summary,
    location: formData.location,
    phone: formData.phone,
    pdf_Link: formData.pdfLink
  }
  emailjs.send(service_id, template_id, template_params, publicKey)
  .then(
    (response) => {
      toast.success("your Application sent to safety company");
      setLoading(false);

      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        location: '',
        phone: '',
        summary: '',
        pdfLink: '' // Reset the uploaded PDF link
      });
    },
    (error) => {
    },
  );
  };
  return (
    <>
          <ScrollToTop />

<div className='lg:grid grid-cols-12 relative lg:mt-[50px] mt-[30px]'>
        <div className='col-span-6 mb-[30px] lg:mb-[0px] px-12'>
          <div className='flex justify-center'>
            <h1 className='text-[#F0940D] lg:text-[80px] text-[40px]  font-bold'>Join <span className='text-black'>Us</span></h1>
          </div>
          <p className='font-bold text-center mt-[20px] lg:mt-[10px]'>
          we believe in building a world where safety is not just a priority but a fundamental right. Every day, our dedicated team works to protect lives and secure environments across industries. We are committed to innovation, excellence, and teamwork, and we invite passionate individuals to join us in our mission to make workplaces, homes, and public spaces safer for everyone            </p>
        </div>
        <div className='col-span-6'>
          <div className='flex justify-end'>
            <img src={careerimg} className='lg:w-[700px] lg:h-[400px]' alt='career' />
          </div>
        </div>
        <div className='bg-[#F0940D] py-8 px-4 absolute lg:bottom-[-60px] bottom-[-120px] lg:right-[40%] rounded-tr-[60px] rounded-bl-[60px] lg:h-[200px] flex justify-center items-center w-[250px] text-[white]'>
          <p className='text-center font-bold'>
          Join Our Mission to Create a Safer Tomorrow            </p>
        </div>
      </div>
      <div className="form lg:w-[75%] m-auto mt-[150px]">
        <div className='w-full h-[10px] border rounded-md bg-[#F0940D]'></div>
        <div className="w-full">
          <form className="bg-white shadow-lg rounded px-8 pt-6 pb-[100px] mb-4" onSubmit={handleSubmit}>
            <div className="text">
              <div className="title flex flex-col justify-center items-center">
                <p className='text-[#F0940D] font-bold mb-[30px] lg:mb-[0px] lg:text-[36px]'>Apply to work with us</p>
              </div>
            </div>
            <div className='grid grid-cols-12 gap-3'>
              <div className="col-span-6">
                <input
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder='First Name*'
                  type="text"
                />
                {formErrors.firstName && (
                <p className="text-red-500 text-xs">
                  {formErrors.firstName._errors[0]}
                </p>
              )}
              </div>
              <div className="col-span-6">
                <input
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder='Last Name*'
                  type="text"
                />
                  {formErrors.lastName && (
                <p className="text-red-500 text-xs">
                  {formErrors.lastName._errors[0]}
                </p>
              )}
              </div>
            </div>
            <div className="mb-4">
              <input
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder='Email*'
                className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="email"
              />
                  {formErrors.email && (
                <p className="text-red-500 text-xs">
                  {formErrors.email._errors[0]}
                </p>
              )}
            </div>
            <div className='grid grid-cols-12 gap-3'>
              <div className="col-span-6">
                <input
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  placeholder='Location*'
                  className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                />
                  {formErrors.location && (
                <p className="text-red-500 text-xs">
                  {formErrors.location._errors[0]}
                </p>
              )}
              </div>
              <div className="col-span-6">
                <input
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder='Phone*'
                  className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  type="tel"
                />
                   {formErrors.phone && (
                <p className="text-red-500 text-xs">
                  {formErrors.phone._errors[0]}
                </p>
              )}
              </div>
            </div>
            <textarea
              name="summary"
              value={formData.summary}
              onChange={handleChange}
              className='w-full'
              placeholder='Box for Summary*'
            />
                 {formErrors.summary && (
                <p className="text-red-500 text-xs">
                  {formErrors.summary._errors[0]}
                </p>
              )}
          <div>
          <p className=" text-md text-[#F0940D] font-bold mt-1">Please upload your CV here.</p> {/* Hint added here */}
          <input 
              className="w-full text-lg px-1 lg:py-8 lg:px-8 file-input-warning border border-black rounded-lg cursor-pointer"
              id="large_size"
              type="file"
              accept="application/pdf" // Accept only PDF files
              onChange={handleFileChange} 
            />
            {formErrors.pdfLink && (
              <p className="text-red-500 text-xs">
                {formErrors.pdfLink._errors[0]}
              </p>
            )}
  {/* <input
    name="pdfLink"
    value={formData.pdfLink}
    onChange={handleChange}
    className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
    placeholder='CV Link*' 
    type="text"
  /> */}
 
</div>
            <div className="flex bg-[#F0940D] w-fit mx-auto text-white rounded-lg items-center justify-center mt-[50px] ">
            <button
              className=" font-semibold px-6 py-3    transition duration-500 ease-in-out"
              type="submit"
              disabled={isLoadingPDf} // Disable if loading
            >
{
  isLoading 
    ? "Sending..." 
    : isLoadingPDf && !isLoading 
      ? "Uploading pdf...." 
      : "Send Application"
}            </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
