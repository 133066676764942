
import React, { useState } from "react";
import AboutUsLayer from "../Layers/About_usLayer";
import Clients from "../Clients/Clients";
import axios from "axios";
import toast from "react-hot-toast";
import ScrollToTop from "../../ScrollToTop";
import { Link } from "react-router-dom";

const Certificate = () => {
  const [errmass, setErrMass] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [data, setData] = useState(null);  // Set default to null
  console.log(data)
  const [formData, setFormData] = useState({
    certificateCode: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getApi = async (formData) => {
    try {
      setIsLoading(true);
      setErrMass(""); // Clear previous errors
      const { data } = await axios.post(
        `https://safty.camp-coding.site/api/certificates/${formData.certificateCode}`
      );
      setData(data); // Store the data
      setIsValid(data.valid); // Update validity status
    
    } catch (error) {
      setIsValid(false);
      setErrMass(error?.response?.data?.message || "Invalid code");
      toast.error(error?.response?.data?.message || "Invalid code");
    } finally {
      setIsLoading(false);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    await getApi(formData);  // Call the API function
  };
  const downloadImage = async (imageUrl) => {
    try {
      // Make the POST request to the download API
      const response = await axios.post('https://safty.camp-coding.site/api/download-image', {
        image_url: imageUrl
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'blob' // This ensures the response is treated as a binary object
      });

      // Trigger download directly from the API response
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      // Extract the image file name from the URL
      const fileName = imageUrl.split('/').pop();
      link.setAttribute('download', fileName); // Set the file name for download

      document.body.appendChild(link);
      link.click(); // Trigger the download
      document.body.removeChild(link); // Clean up the link after download
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };

  return (
    <>
          <ScrollToTop />

      <AboutUsLayer />

      <div className="container mx-auto px-4 py-8">
        {/* Search Section */}
        <div className="bg-[#F0940D] lg:w-[50%] mx-auto p-6 rounded-lg shadow-md text-center">
          <h2 className="text-white text-lg mb-4">Enter certificate code</h2>
          <div className="flex flex-wrap justify-center items-center  gap-4">
            <input
              type="text"
              className="p-2  rounded-md border border-gray-300 w-full max-w-xs"
              placeholder="Enter certificate code"
              name="certificateCode"
              value={formData.certificateCode}
              onChange={handleChange}
            />
            <button
              onClick={handleSubmit}
              className="bg-white md:w-auto  w-full text-[#F0940D] px-4 py-2 rounded-md hover:bg-gray-100 transition-all"
            >
              {isLoading ? "Searching..." : "Search"}
            </button>
          </div>
        </div>

        {/* Display data based on search result */}
        {data ? (
          <div className="mt-8 ">
            {data.valid ? (
              <div className="flex justify-center items-center">
                <div className="bg-green-500 mb-[20px] flex justify-center items-center w-[200px] text-white font-bold rounded-t px-2 py-2">
                Valid
                </div>
              </div>
            ) : (
              <div className="flex justify-center items-center">
                <div className="bg-red-500 mb-[20px] flex justify-center items-center w-[200px] text-white font-bold rounded-t px-2 py-2">
                  Expired
                </div>
              </div>
            )}

            {/* Display certificate image */}
            {data.images.length>0 && (
       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
       {data.images.map((image, index) => (
         <div key={index} className="relative">
           <img
             src={image.image_url}
             alt={`Certificate ${index + 1}`}
             className="w-full rounded-lg shadow-lg h-[450px] mx-auto mb-4"
           />
           <div
             onClick={() => downloadImage(image.image_url)}
             className="absolute top-5 bg-white rounded-full p-1 left-10 cursor-pointer"
           >
             <svg
               width="20"
               height="20"
               viewBox="0 0 25 25"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
             >
               <path
                 d="M12.4997 18.3333L5.20801 11.0416L7.24967 8.92706L11.0413 12.7187V0.833313H13.958V12.7187L17.7497 8.92706L19.7913 11.0416L12.4997 18.3333ZM3.74967 24.1666C2.94759 24.1666 2.2612 23.8813 1.69051 23.3106C1.11981 22.7399 0.83398 22.053 0.833008 21.25V16.875H3.74967V21.25H21.2497V16.875H24.1663V21.25C24.1663 22.0521 23.881 22.7389 23.3103 23.3106C22.7396 23.8823 22.0527 24.1676 21.2497 24.1666H3.74967Z"
                 fill="#F0940D"
               />
             </svg>
           </div>
         </div>
       ))}
     </div>
     
        
            )}
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <p className={`${errmass?'text-red-600':''} font-bold mt-[30px] text-[25px]`}>

              {errmass?<>             {errmass}
                </>:<>
                              Search to see your certificate
</>}
            </p>
          </div>
        )}
      </div>
      <Clients />
    </>
  );
};

export default Certificate;
